var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"timelineElement",attrs:{"id":"progress-timeline"}},[_c('label',[_vm._v("Progress")]),_vm._l((_vm.DilemmaCompiler),function(item,i){return [_c('div',{key:'chapter-'+i,class:Object.assign({}, {'chapter': true, 
        'past': i < _vm.currentChapterIndex, 
        'current': i == _vm.currentChapterIndex}, 
        item.flags.reduce(function (obj, flag) {
              var _obj;

              return (Object.assign({}, obj, ( _obj = {}, _obj[flag] = true, _obj )));
}, {}))}),(i < _vm.DilemmaCompiler.length-1)?_c('div',{key:'spacer-'+i,staticClass:"spacer"}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }